.container {
  margin-bottom: var(--spacing-bottom);
  padding-inline: var(--spacing-sides);

  @media (width >= 1000px) {
    margin-bottom: initial;
  }
}

.inner {
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  margin-inline: auto;
  max-width: var(--max-width-revamp-base);

  @media (width >= 1000px) {
    flex-direction: row;
  }
}

.content {
  align-items: center;

  @media (width >= 1000px) {
    flex-basis: 50%;
    margin-block: var(--spacing-xxxx-loose);
  }
}

.button-wrapper {
  --button-wrapper-flex-direction: row;
  --button-wrapper-justify-content: center;

  @media (width >= 1000px) {
    --button-wrapper-justify-content: start;
  }
}

.teaser {
  max-width: 640px; /* magic number*/
}

.image-wrap {
  display: flex;

  justify-content: center;
  margin-inline: auto;
  max-width: 448px; /* magic number */

  @media (width >= 1000px) {
    flex-basis: 50%;
  }
}
